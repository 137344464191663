import incHeader from "../inc-header.vue";
export default {
  components: {
    incHeader
  },
  props: {
    mobile: Boolean
  },

  data() {
    return {
      menu: [],
      show1: false,
      show2: false,
      show3: false,
      password: "",
      password1: "",
      password2: "",
      errPass: "",
      disabled: true,
      rules: [v => !!v || "不能为空", v => v && v.length >= 6 || "密码不能少于6位字符"],
      snackbar: false,
      snackbarBackground: "red",
      errmsg: "",
      dialog: false
    };
  },

  methods: {
    checkPassword() {
      if (this.password == null) return false;

      if (this.password.length >= 6 && this.password1.length >= 6 && this.password1 === this.password) {
        this.errPass = "新密码与旧密码不能相同";
      } else if (this.password1.length >= 6 && this.password2.length >= 6) {
        if (this.password1 !== this.password2) {
          this.errPass = "新密码与确认密码必须相同";
        } else {
          this.errPass = "";
        }
      } else {
        this.errPass = "";
      }
    },

    postPasswd() {
      if (this.$refs.form.validate() == false) return false;
      let data = {
        module: "usinfo",
        title: "repasswd",
        data: {
          passwd_old: this.password,
          passwd: this.password1
        }
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        ////console.log(res)
        if (res.data.usinfo.errcode == 0) {
          //that.password = that.password1 = that.password2 = "";
          //that.$refs.form.reset()
          that.dialog = true;
        } else {
          that.snackbar = true;
          that.snackbarBackground = "red";
          that.errmsg = res.data.usinfo.errmsg;
        }
      });
    },

    ckpass() {
      if (this.password === null) return false;

      if (this.password.length >= 6 && this.password1.length >= 6 && this.password1 === this.password2) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },

    rewindow() {
      //this.$router.go(0)
      //window.location.reload();
      //this.$refs.form.resetValidation();
      this.$refs.form.reset();
      this.dialog = false;
      return false;
    }

  },
  watch: {
    password() {
      this.ckpass();
    },

    password1() {
      this.ckpass();
    },

    password2() {
      this.ckpass();
    }

  },

  created() {//this.$parent.$parent.$parent.selectedItem = 3;
  }

};